// import React from "react";
// import eContriverLogo from "./eContriver_logo1.png";

// function Logo() {
//   return (
//     <a href="/" className="d-flex justify-content-center">
//       <img
//         src={eContriverLogo}
//         style={{
//           width: 48 + "px",
//           height: 48 + "px",
//         }}
//         alt="eContriver"
//       />
//     </a>
//   );
// }

export default function Header() {
  return "";
  //   <div className="container sticky-top rounded-bottom shadow-lg mb-1 border-secondary border border-top-0">
  //     <div className="row site-header align-items-center">
  //       <Logo />
  //     </div>
  //   </div>
  // );
}
